
import React from 'react';
import Webheader from '../../components/web/header';

const Rice72 = () => {
    return (
        <div>
            <header
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    zIndex: 2,
                }}
            >
                <Webheader pageTitle="พันธุ์ข้าวรับรอง 10 พันธุ์" menu={2} />
            </header>
            <iframe
                title="Matching"
                src="https://top10breed.vercel.app/"
                style={{
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    width: '100%',
                    border: 'none',
                    margin: 0,
                    padding: 0,
                    overflow: 'hidden',
                    zIndex: 1,
                    height: 'calc(100% - 80px)' // ลบความสูงของ header จากความสูงทั้งหน้าเพจ
                }}
            />
        </div>
    );
}

export default Rice72